import { UserDeviceGroupRole } from '../../__generated__/graphql';
import i18n from '../../i18n';

export const mapUserDeviceGroupRole = (userRole: UserDeviceGroupRole): string => {
  switch (userRole) {
    case UserDeviceGroupRole.DeviceAdministrator:
      return i18n.t('displayDeviceGroupRole.deviceAdministrator');
    case UserDeviceGroupRole.DeviceEngineer:
      return i18n.t('displayDeviceGroupRole.deviceEngineer');
    case UserDeviceGroupRole.DeviceObserver:
      return i18n.t('displayDeviceGroupRole.deviceObserver');
    case UserDeviceGroupRole.DeviceOperator:
      return i18n.t('displayDeviceGroupRole.deviceOperator');
    default:
      return i18n.t('noData');
  }
};
