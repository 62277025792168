import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { isEmpty, isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, JSX, SetStateAction, useContext, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MainOperationEditRequest, mainOperationEditSchema } from './validation-schema';
import {
  GetAllDeviceOperationsPerformanceQuery,
  GetDeviceDetailsQuery,
  OperationalLifeCycle
} from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs';
import { MUTATION_MODIFY_DEVICE_OPERATION } from '../../../../services/mutations';
import { QUERY_GET_OPERATION_RESULTS } from '../../../../services/queries';
import { SnackbarMessageType } from '../../../../types';
import { constructSnackbarMessage, mapOperationalLifeCycleDisplayLabel } from '../../../../utilities';
import { ConfirmationModal, OperationResultOption } from '../../../4-features';
import { Loading, RSButton, RSSelect, RSSelectItemProps, RSTextInput } from '../../../5-elements';
import { AutoRefreshContext } from '../../../contexts';

interface MainOperationEditProps {
  setEditMode: Dispatch<SetStateAction<boolean>>;
  defaultValues: MainOperationEditRequest;
  refetch: () =>
    | Promise<ApolloQueryResult<GetDeviceDetailsQuery>>
    | Promise<ApolloQueryResult<GetAllDeviceOperationsPerformanceQuery>>;
}

export const MainOperationEdit = ({ setEditMode, defaultValues, refetch }: MainOperationEditProps): JSX.Element => {
  const { t } = useTranslation();
  const { autoRefresh } = useContext(AutoRefreshContext);
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const {
    loading: loadingOperationResults,
    data: dataOperationResults,
    error: errorOperationResults
  } = useQuery(QUERY_GET_OPERATION_RESULTS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      sendMessageToSnackbar(
        t('operationsPage.operationDetails.annotationHistory.errorFetchOperationResultOptions'),
        undefined,
        error.message || error.name,
        'error'
      );
    }
  });
  const [modifyOperation, { loading }] = useMutation(MUTATION_MODIFY_DEVICE_OPERATION, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset
  } = useForm<MainOperationEditRequest>({
    resolver: zodResolver(mainOperationEditSchema),
    defaultValues
  });

  const operationalLifeCycleValues = Object.values(OperationalLifeCycle);
  const operationalLifeCycleSelectOptions: RSSelectItemProps[] = operationalLifeCycleValues.map((item) => ({
    displayName: mapOperationalLifeCycleDisplayLabel(item),
    menuItemProps: { value: item }
  }));

  const operationResultResponse = dataOperationResults?.deviceOperationResults || [];
  const operationResultRevertIncluded = [
    // Other parts does not matter, we want a "revert" in order to revert it to the original result
    { id: '11111111-2222-3333-4444-555555555555', code: 'REVERT', name: 'Revert', isSuccessful: false },
    ...operationResultResponse
  ];
  const operationResultSelectOptions: RSSelectItemProps[] = operationResultRevertIncluded.map((item) => ({
    displayName: <OperationResultOption resultObject={item} />,
    menuItemProps: { value: item.code }
  }));

  const onSubmit: SubmitHandler<MainOperationEditRequest> = (data) => {
    const formHasChanges = !isEqual(getValues(), defaultValues);
    if (!formHasChanges) {
      setEditMode(false);
      return;
    }

    const dataToSubmit: MainOperationEditRequest = {
      ...data,
      annotatedResultCode: data.annotatedResultCode === 'REVERT' ? null : data.annotatedResultCode
    };

    modifyOperation({
      variables: { deviceOperationInput: dataToSubmit },
      onCompleted: () => {
        if (!autoRefresh) {
          refetch();
        }
        sendMessageToSnackbar(t('forms.snackbarMessages.successfullySaved'), undefined, undefined, 'success');
        setEditMode(false);
        reset(data);
      }
    });
  };

  const handleLeave = (): void => {
    reset();
    setShowConfirmationModal(false);
    setEditMode(false);
  };

  const handleCancel = (): void => {
    const formHasChanges = !isEqual(getValues(), defaultValues);
    if (formHasChanges) {
      setShowConfirmationModal(true);
    } else {
      setEditMode(false);
    }
  };

  if (loadingOperationResults) {
    return (
      <div className="main-operation-edit main-operation-edit__loading" data-testid="main-operation-edit-loading">
        <Loading />
      </div>
    );
  }

  /*
    It is the best practice to use <form> tag for "main-operation-edit", however here <div> is used.
    If <form> is used, when the confirmation modal appears, it will automatically submit the form, which is undesired.
    Therefore <div> is used to avoid the default behaviour of the HTML element <form>.
  */
  return (
    <div className="main-operation-edit" data-testid="main-operation-edit">
      <div className="main-operation-edit__form-contents">
        <div className="main-operation-edit__annotated-result-code">
          <Controller
            name="annotatedResultCode"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="main-operation-edit__annotated-result-code-select"
                inputLabel="Result"
                required={true}
                disabled={Boolean(errorOperationResults)}
                menuItems={operationResultSelectOptions}
                helperText={errors.annotatedResultCode?.message}
                error={Boolean(errors.annotatedResultCode)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="input-operation-edit-annotated-result"
                value={value}
              />
            )}
          />
        </div>
        <div className="main-operation-edit__operational-life-cycle">
          <Controller
            name="operationalLifeCycle"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <RSSelect
                className="main-operation-edit__operational-life-cycle-select"
                inputLabel="Operational lifecycle"
                required={true}
                menuItems={operationalLifeCycleSelectOptions}
                helperText={errors.operationalLifeCycle?.message}
                error={Boolean(errors.operationalLifeCycle)}
                onBlur={onBlur}
                onChange={onChange}
                data-testid="input-operation-edit-operational-life-cycle"
                value={value}
              />
            )}
          />
        </div>
        <div className="main-operation-edit__notes">
          <RSTextInput
            inputLabel="Notes"
            className="main-operation-edit__notes-input"
            data-testid="input-operation-edit-notes-input"
            multiline={true}
            helperText={errors.remark?.message}
            error={Boolean(errors.remark)}
            {...register('remark')}
          />
        </div>
      </div>
      <div className="main-operation-edit__button-group">
        <RSButton
          onClick={handleCancel}
          disabled={loading || !isEmpty(errors)}
          data-testid="main-operation-edit-cancel"
          className="main-operation-edit__action"
        >
          {t('operationsPage.operationDetails.annotationHistory.cancel')}
        </RSButton>
        <RSButton
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
          data-testid="main-operation-edit-submit"
        >
          {t('operationsPage.operationDetails.annotationHistory.save')}
        </RSButton>
      </div>
      <ConfirmationModal
        open={showConfirmationModal}
        mainTitle={t('forms.confirmationModal.confirmModalTitle')}
        message={t('forms.confirmationModal.unsavedChangesMessage')}
        confirmButtonText={t('forms.confirmationModal.confirmActionButtonText')}
        cancelButtonText={t('forms.confirmationModal.cancelActionButtonText')}
        handleClickCancelButton={handleLeave}
        handleClickConfirmButton={() => setShowConfirmationModal(false)}
        disableConfirmButton={loading}
        disableCancelButton={loading}
      />
    </div>
  );
};
