import { GridColDef } from '@mui/x-data-grid';

import { CompanyDeviceGroupRow } from './generate-customer-device-group-rows';
import i18n from '../../../../i18n';
import { CompanyType } from '../../../../types';

export const companyDeviceGroupsDataGridColumns = (companyType: CompanyType): GridColDef<CompanyDeviceGroupRow>[] => {
  return [
    {
      field: 'name',
      hideable: false,
      sortable: true,
      flex: 2.4,
      minWidth: 240,
      headerClassName: 'admin-details-data-grid__data-grid-row-padding-left',
      cellClassName: 'admin-details-data-grid__data-grid-row-padding-left',
      headerName: i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.name')
    },
    {
      field: companyType === CompanyType.Customer ? 'serviceProvider' : 'customer',
      hideable: false,
      sortable: true,
      flex: 2.4,
      minWidth: 240,
      headerName:
        companyType === CompanyType.Customer
          ? i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.serviceProvider')
          : i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.customer')
    },
    {
      field: 'deviceCount',
      hideable: false,
      sortable: true,
      flex: 1.4,
      minWidth: 140,
      headerName: i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.deviceCount')
    },
    {
      field: 'userCount',
      hideable: false,
      sortable: true,
      flex: 1.4,
      minWidth: 140,
      headerName: i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.userCount')
    },
    {
      field: 'createdAt',
      hideable: false,
      sortable: true,
      flex: 1.8,
      minWidth: 180,
      headerName: i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.createdAt')
    },
    {
      field: 'updatedAt',
      hideable: false,
      sortable: true,
      flex: 1.8,
      minWidth: 180,
      headerClassName: 'admin-details-data-grid__data-grid-row-padding-right',
      cellClassName: 'admin-details-data-grid__data-grid-row-padding-right',
      headerName: i18n.t('companyAdminDetailPage.deviceGroupsDataGrid.updatedAt')
    }
  ];
};
