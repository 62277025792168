import { JSX } from 'react';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

import {
  CompaniesAdminPage,
  CompanyDetailsAdminPage,
  CustomerDetailsPage,
  CustomersPage,
  DeviceDetailsPage,
  DevicesPage,
  OperationsPage,
  PageNotFoundErrorPage,
  TestPage,
  UsersAdminPage
} from './1-pages';
import { PageWrapper } from './2-templates';
import { getRedirectFullPath } from '../utilities';
import { DevicesAdminPage } from './1-pages/devices-admin-page';

const RedirectToDevicePage = (): JSX.Element => {
  const { deviceId } = useParams();
  return <Navigate to={`/portal/devices/${deviceId}`} replace={true} />;
};

export const ApplicationRoutes = (): JSX.Element => {
  const routerLocation = useLocation();
  const fullPath = getRedirectFullPath(routerLocation);

  // Note: The <PageWrapper> MUST be in this level (not to be in every page component).
  // Putting <PageWrapper> in each page component triggers Auth0 to re-fetch the user avatar (make remote request)
  return (
    <Routes>
      <Route path="/portal" element={<Navigate to="customers" replace={true} />} />
      <Route
        path="/portal/customers"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CustomersPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/customers/:customerId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CustomerDetailsPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/devices"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DevicesPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/devices/:deviceId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DeviceDetailsPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/operations"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <OperationsPage />
          </PageWrapper>
        }
      />
      <Route path="/portal/admin" element={<Navigate to="/portal/admin/companies" replace={true} />} />
      <Route
        path="/portal/admin/companies"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CompaniesAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/companies/:companyId"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <CompanyDetailsAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/users"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <UsersAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/admin/devices"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <DevicesAdminPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/components-demo"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={true}>
            <TestPage />
          </PageWrapper>
        }
      />
      <Route
        path="/portal/callback"
        element={<PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={false} />}
      />
      {/* 
        redirect from old to new device page (used in teams notifications)
        portal/rocs/detail-screen/item/<device-uuid>
        portal/devices/<device-uuid> */}
      <Route path="/portal/rocs/detail-screen/item/:deviceId" element={<RedirectToDevicePage />} />
      <Route
        path="*"
        element={
          <PageWrapper loginRequired={true} loginRedirectUri={fullPath} hasNavigation={false}>
            <PageNotFoundErrorPage />
          </PageWrapper>
        }
      />
    </Routes>
  );
};
